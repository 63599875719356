import React from 'react'
import { ThemeProvider } from '@emotion/react'

const colors = {
  purple: '#7069FC',
  blue: '#00142D',
  text: '#777E91',
  gold: '#ECA72V',
  buttonText: '#00142D'
}

const theme = {
  colors
}

const DefaultThemeProvider = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}

export default DefaultThemeProvider
